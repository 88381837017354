import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-2" }
const _hoisted_2 = { class: "col-12 col-md-6" }
const _hoisted_3 = { class: "col-12 col-md-6 mt-2 mt-md-0" }
const _hoisted_4 = { class: "row g-5 g-xl-8" }
const _hoisted_5 = { class: "col-xl-6" }
const _hoisted_6 = { class: "col-xl-6" }
const _hoisted_7 = { class: "col-xl-12" }
const _hoisted_8 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompaniesDropdown = _resolveComponent("CompaniesDropdown")!
  const _component_BranchesDropdown = _resolveComponent("BranchesDropdown")!
  const _component_JobsStatistics = _resolveComponent("JobsStatistics")!
  const _component_ReportedHoursStatistic = _resolveComponent("ReportedHoursStatistic")!
  const _component_JobsByCompanyPie = _resolveComponent("JobsByCompanyPie")!
  const _component_UsersStatistic = _resolveComponent("UsersStatistic")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CompaniesDropdown, { onChange: _ctx.handleCompanyFilter }, null, 8, ["onChange"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BranchesDropdown, { onChange: _ctx.handleBranchesFilter }, null, 8, ["onChange"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createBlock(_component_JobsStatistics, { key: _ctx.key }))
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(), _createBlock(_component_ReportedHoursStatistic, { key: _ctx.key }))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_JobsByCompanyPie)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_UsersStatistic)
      ])
    ])
  ], 64))
}
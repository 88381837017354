<template>
  <div class="row gy-5 g-xl-8">
    <StatisticsIndex widget-classes="card-xxl-stretch mb-5 mb-xl-8"></StatisticsIndex>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import StatisticsIndex from "@/components/statistics/index.vue";

export default defineComponent({
  name: "statistics-view",
  components: {
    StatisticsIndex
  },
  mounted() {
    setCurrentPageTitle("Statistics");
  }
});
</script>
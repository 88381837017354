
import { defineComponent, reactive, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "new-users-statistics-widget",
  setup() {
    const store = useStore();
    const key = ref<number>(0);

    const data = computed(() => store.getters["StatisticsModule/users"]);
    const chart = reactive({
      data: [
        {
          name: "Registered users",
          data: [] as string | unknown
        },
        {
          name: "Active users",
          data: [] as string | unknown
        }
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        noData: {
          text: "Loading..."
        },
        xaxis: {
          type: "category",
          categories: [] as string[]
        },
        tooltip: {
          x: {
            format: "MMM yyyy"
          }
        }
      }
    });
    const setData = () => {
      chart.options.xaxis.categories = Object.keys(data.value.registeredUsers);
      chart.data[0].data = Object.values(data.value.registeredUsers);
      chart.data[1].data = Object.values(data.value.activeUsers);
      key.value++;
    };
    function fetchStatistics() {
      store.dispatch("StatisticsModule/users").then(() => {
        setData();
      });
    }
    onMounted(() => {
      fetchStatistics();
    });
    return {
      chart,
      data,
      key
    };
  }
});

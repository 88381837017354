
import { defineComponent, reactive, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "jobs-by-company-pie",
  setup() {
    const store = useStore();
    const key = ref<number>(0);

    const data = computed(
        () => store.getters["StatisticsModule/jobsPerClient"]
    );
    const chart = reactive({
      options: {
        labels: [],
        series: [],
        noData: {
          text: "Loading..."
        },
        chart: {
          width: 380,
          type: 'pie',
        }
      }
    });
    const setData = () => {
      chart.options.labels = data.value.labels;
      chart.options.series = data.value.values;
      key.value++;
    };
    function fetchStatistics() {
      store.dispatch("StatisticsModule/fetchJobsPerClient").then(() => {
        setData();
      });
    }
    onMounted(() => {
      fetchStatistics();
    });
    return {
      chart,
      data,
      key
    };
  }
});

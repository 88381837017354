
import { defineComponent, onUnmounted, ref } from "vue";
import CompaniesDropdown from "@/components/dropdown/ClientDropdown.vue";
import JobsStatistics from "@/components/statistics/charts/JobsStatistic.vue";
import BranchesDropdown from "@/components/dropdown/BranchesDropdownMultiple.vue";
import ReportedHoursStatistic from "@/components/statistics/charts/ReportedHoursStatistic.vue";
import JobsByCompanyPie from "@/components/statistics/charts/JobsByCompanyPie.vue";
import UsersStatistic from "@/components/statistics/charts/UsersStatistic.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    JobsStatistics,
    CompaniesDropdown,
    BranchesDropdown,
    ReportedHoursStatistic,
    JobsByCompanyPie,
    UsersStatistic
  },
  setup() {
    const store = useStore();
    const key = ref<number>(0);
    const handleCompanyFilter = (id: number | string | null) => {
      store.dispatch("StatisticsModule/setCompanyFilter", id);
      key.value++;
    }
    function handleBranchesFilter(ids: number[] | string | null) {
      store.dispatch("StatisticsModule/setBranchesFilter", ids);
      key.value++;
    }
    onUnmounted(() => {
      store.dispatch("StatisticsModule/resetState");
    });
    return {
      handleBranchesFilter,
      handleCompanyFilter,
      key
    }
  }
});
